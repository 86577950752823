import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Image from '../../common/components/Image';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';
import Container from '../../common/components/UI/Container';
import ARROW from '../../common/assets/image/crypto/round_arrow.svg';

// ** Utils
import { roundNumber } from '../../common/utils';

// **
import { Row, Col } from 'reactstrap';

import {
  PRICING_TABLE_PRICE,
  PRICING_TABLE_CONTENT,
} from '../../common/data/Crypto';

import PricingTable, {
  PricingHead,
  PricingPrice,
  PricingButton,
  PricingList,
  ListItem,
  PricingButtonWrapper,
} from './pricing.style';

import Icon from 'react-icons-kit';
import { arrows_check } from 'react-icons-kit/linea/arrows_check';

const PricingSection = ({
  sectionWrapper,
  row,
  secTitleWrapper,
  secHeading,
  secText,
  nameStyle,
  descriptionStyle,
  priceStyle,
  priceLabelStyle,
  buttonStyle,
  buttonFillStyle,
  listContentStyle,
  excludeFreePlan,
}) => {
  const setPrice = (type) => {
    let result = PRICING_TABLE_CONTENT.map((item, index) => {
      item.price = PRICING_TABLE_PRICE[type].prices[index];
      item.priceLabel = PRICING_TABLE_PRICE[type].labels[index];
      item.months = PRICING_TABLE_PRICE[type].months;
      return item;
    });
    if (excludeFreePlan) {
      result = result.filter((x) => x.freePlan !== true);
    }
    // JSON.parse/stringify to update view
    return JSON.parse(JSON.stringify(result));
  };

  const [state, setState] = useState({
    data: setPrice('monthly'),
    active: 1,
  });

  // const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   setTimeout(function () {
  //     setLoading(true);
  //   }, 500);
  // });

  const data = state.data;
  const activeStatus = state.active;

  // const pricingCarouselOptions = {
  //   type: "slider",
  //   perView: 3,
  //   gap: 30,
  //   bound: true,
  //   breakpoints: {
  //     1199: {
  //       perView: 2,
  //       type: "carousel",
  //       peek: {
  //         before: 100,
  //         after: 100,
  //       },
  //     },
  //     990: {
  //       type: "carousel",
  //       perView: 1,
  //       peek: {
  //         before: 160,
  //         after: 160,
  //       },
  //     },
  //     767: {
  //       type: "carousel",
  //       perView: 1,
  //       peek: {
  //         before: 80,
  //         after: 80,
  //       },
  //     },
  //     575: {
  //       type: "carousel",
  //       perView: 1,
  //       gap: 15,
  //       peek: {
  //         before: 20,
  //         after: 20,
  //       },
  //     },
  //   },
  // };

  return (
    <Box {...sectionWrapper} id="pricing_section" className="pt-5 pt-sm-1">
      <Container>
        <Box {...secTitleWrapper}>
          {/* <Text {...secText} content="PRICING PLAN" /> */}
          <Heading
            {...secHeading}
            content="Plans for every level of ambition"
          />
          <Text
            {...secText}
            content="All Bitexbot subscriptions come with a free 7-day PREMIUM trial."
          />
          <PricingButtonWrapper className="d-flex justify-content-center">
            <Button
              title="Monthly"
              className={activeStatus === 1 ? 'active-item' : ''}
              onClick={() => setState({ data: setPrice('monthly'), active: 1 })}
            />
            <Button
              title="3 Months"
              badge="-6%"
              badgeColor="primary"
              className={activeStatus === 2 ? 'active-item' : ''}
              onClick={() =>
                setState({ data: setPrice('months_3'), active: 2 })
              }
            />
            <Button
              title="6 Months"
              badge="-12%"
              badgeColor="primary"
              className={activeStatus === 3 ? 'active-item' : ''}
              onClick={() =>
                setState({ data: setPrice('months_6'), active: 3 })
              }
            />
            <Button
              title="Annual"
              badge="-16%"
              badgeColor="danger"
              className={activeStatus === 4 ? 'active-item' : ''}
              onClick={() => setState({ data: setPrice('annual'), active: 4 })}
            />
          </PricingButtonWrapper>
          <div className="d-flex justify-content-center mt-3">
            <div className="text-center alert alert-info m-2 col-md-10 col-lg-6">
              <div className="p-1 text-dark">
                Annually you have 16% off which is 2 months free{' '}
                <span
                  style={{ fontSize: 20 }}
                  role="img"
                  aria-label="Heart Eyes"
                >
                  😍
                </span>
              </div>
            </div>
            <Image
              src={ARROW}
              alt="Annually you have 16% off which is 2 months free"
              className="d-none d-md-block"
            />
          </div>
        </Box>
        <Row>
          <>
            {data.map((item, index) => (
              <Col
                key={index}
                md={12 / data.length}
                xs="12"
                className={'py-md-0 '.concat(
                  index === 0
                    ? 'pb-2' // First
                    : index === data.length - 1
                    ? 'pt-2' // Last
                    : 'py-2' // Middle
                )}
              >
                <PricingTable
                  freePlan={item.freePlan}
                  className={`pricing_table bg-gradient h-100`}
                  style={{
                    background:
                      index === 0
                        ? '#f7f7f7'
                        : index === 1
                        ? '#e3eefc'
                        : '#6d4ffe',
                  }}
                >
                  <PricingHead>
                    <Heading
                      content={item.name}
                      className={index === 2 ? 'text-white' : ''}
                      {...nameStyle}
                    />
                    <Text
                      content={item.description}
                      className={index === 2 ? 'text-light' : ''}
                      {...descriptionStyle}
                    />
                  </PricingHead>
                  <PricingPrice>
                    <Text
                      sup="$"
                      content={item.price}
                      className={index === 2 ? 'text-white h1' : 'h1'}
                      {...priceStyle}
                    />
                    <Text
                      content={item.priceLabel}
                      // className={
                      //   activeStatus > 1 && !item.freePlan ? "mb-0" : ""
                      // }
                      className={index === 2 ? 'text-light' : ''}
                      {...priceLabelStyle}
                    />
                    {activeStatus > 1 && !item.freePlan ? (
                      <Text
                        content={
                          <small
                            className={`annual-pricing ${
                              index === 2 ? 'text-light' : 'text-muted'
                            } mb-0`}
                          >
                            {`USD ${roundNumber(
                              parseFloat(item.price) * item.months
                            )} / ${
                              item.months === 12
                                ? 'year'
                                : `${item.months} months`
                            }`}
                          </small>
                        }
                        className={index === 2 ? 'text-light' : ''}
                        {...priceLabelStyle}
                      />
                    ) : null}
                  </PricingPrice>
                  <PricingButton>
                    <a href={item.url}>
                      {index === 2 ? (
                        <Button title={item.buttonLabel} {...buttonFillStyle} />
                      ) : (
                        <Button title={item.buttonLabel} {...buttonStyle} />
                      )}
                    </a>
                  </PricingButton>
                  <PricingList>
                    {item.listItems.map((item, i) => (
                      <ListItem key={`pricing-table-list-${i}`}>
                        <Icon
                          icon={arrows_check}
                          size={20}
                          className={
                            index === 2 ? 'text-white' : 'text-success'
                          }
                        />
                        <Text
                          content={
                            <span
                              dangerouslySetInnerHTML={{ __html: item.content }}
                            />
                          }
                          sub={item.subcontent}
                          className={index === 2 ? 'text-white' : ''}
                          {...listContentStyle}
                        />
                      </ListItem>
                    ))}
                  </PricingList>
                </PricingTable>
              </Col>
            ))}
          </>
        </Row>
      </Container>
    </Box>
  );
};

PricingSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  nameStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  priceStyle: PropTypes.object,
  priceLabelStyle: PropTypes.object,
  listContentStyle: PropTypes.object,
};

PricingSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['20px', '30px', '30px', '40px', '50px'],
    pb: ['10px', '10px', '10px', '10px'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  secTitleWrapper: {
    mb: ['20px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '15px',
    mb: '5px',
    mt: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    pr: '15px',
    pl: '15px',
  },
  nameStyle: {
    fontSize: ['20px', '20px', '22px', '22px', '22px'],
    fontWeight: '500',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    textAlign: 'center',
    mb: '12px',
  },
  descriptionStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  priceStyle: {
    as: 'span',
    display: 'block',
    fontSize: ['36px', '36px', '40px', '40px', '40px'],
    color: 'headingColor',
    textAlign: 'center',
    mb: '5px',
    letterSpacing: '-0.025em',
  },
  priceLabelStyle: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    border: '1px solid #6d4ffe',
    pl: '10px',
    pr: '10px',
    bg: 'rgba(0,0,0,0)',
    color: '#6d4ffe',
    colors: 'primaryWithBg',
    width: '222px',
    maxWidth: '100%',
  },
  buttonFillStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    border: '1px solid #ffffff',
    pl: '10px',
    pr: '10px',
    bg: 'rgba(0,0,0,0)',
    colors: 'secondaryWithBg',
    width: '200px',
    maxWidth: '100%',
  },
  listContentStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    mb: '0',
  },
};

export default PricingSection;
