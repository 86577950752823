import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { cryptoTheme } from '../common/theme/crypto';
import { ResetCSS } from '../common/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/crypto.style';
import { DrawerProvider } from '../common/contexts/DrawerContext';
import Navbar from '../containers/Navbar';
import Banner from '../containers/BannerSection';
import FeaturesSection from '../containers/Features';
import PricingSection from '../containers/Pricing';
import Faqs from '../containers/Faq';
import ExchangesSections from '../containers/Exchanges';
import Footer from '../containers/Footer';
import Seo from 'components/seo';
import BitexBotOpenGraph from '../common/assets/image/crypto/open-graph/bitexbot-crypto-trading-platform.webp';

const Homepage = () => {
  return (
    <Fragment>
      <Seo
        title="Bitcoin & 250+ CryptoCurrency Trading Platform"
        image={{
          src: BitexBotOpenGraph,
          height: 1200,
          width: 630,
        }}
      />
      <ThemeProvider theme={cryptoTheme}>
        <Fragment>
          <ResetCSS />
          <GlobalStyle />
          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <Banner />
            <FeaturesSection />
            <ExchangesSections />
            <PricingSection />
            <Faqs />
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    </Fragment>
  );
};
export default Homepage;
