import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from '../../common/components/Box';
import Image from '../../common/components/Image';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import FeatureBlock from '../../common/components/FeatureBlock';
import Container from '../../common/components/UI/Container';
import { ExchangesWrapper } from './exchange.style';
import ExchangesImage from '../../common/assets/image/crypto/exchanges.svg';
import { Row, Col } from 'reactstrap';
import { StaticImage } from 'gatsby-plugin-image';

const ExchangesSection = ({
  row,
  col,
  title,
  description,
  btnStyle,
  sectionSubTitle,
  cardArea,
  readMoreTitle,
}) => {
  const BinanceImage =
    '../../common/assets/image/crypto/exchanges/binance.webp';
  const BinanceFuturesImage =
    '../../common/assets/image/crypto/exchanges/binance-futures.webp';
  const BybitImage = '../../common/assets/image/crypto/exchanges/bybit.webp';
  const BitmexImage = '../../common/assets/image/crypto/exchanges/bitmex.webp';
  const KrakenImage = '../../common/assets/image/crypto/exchanges/kraken.webp';

  return (
    <ExchangesWrapper
      id="exchanges_section"
      className="pt-5 pt-sm-1 pb-4 pb-sm-0"
    >
      <Container>
        <Box className="row" {...row}>
          <Box className="colleft" {...col} style={{ flexDirection: 'column' }}>
            <Image
              src={ExchangesImage}
              className="controlImage"
              alt="Currently supported Exchanges are Binance, Binance Futures, Bybit and BitMEX"
            />
          </Box>
          <Box className="colright mt-sm-1" {...col} {...cardArea}>
            <Text {...sectionSubTitle} />
            <FeatureBlock
              title={<Heading {...title} />}
              description={
                <Fragment>
                  <Row className="mt-2">
                    <Col>
                      <StaticImage src={BinanceImage} alt="Binance Logo" />
                    </Col>
                    <Col>
                      <StaticImage
                        src={BinanceFuturesImage}
                        alt="Binance Futures Logo"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <StaticImage src={BitmexImage} alt="BitMEX Logo" />
                    </Col>
                    <Col>
                      <StaticImage src={BybitImage} alt="Bybit Logo" />
                    </Col>
                    <Col>
                      <StaticImage src={KrakenImage} alt="Kraken Logo" />
                    </Col>
                  </Row>
                  <Text
                    className="text-success fw-bolder"
                    content={'+ ALL TESTNETS'}
                  />
                  <Text {...description} />
                </Fragment>
              }
            />
          </Box>
        </Box>
      </Container>
    </ExchangesWrapper>
  );
};

// Transactions style props
ExchangesSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  readMoreTitle: PropTypes.object,
  btnStyle: PropTypes.object,
};

// Transactions default style
ExchangesSection.defaultProps = {
  // Transactions section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Transactions section col default style
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, 1 / 2, 1 / 2, 1 / 2, 1 / 2],
    flexBox: true,
    alignSelf: 'center',
  },

  // Transactions section title default style
  title: {
    content: 'Supported Exchanges',
    fontSize: ['24px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '100%', '415px'],
    textAlign: ['left', 'left'],
  },
  // Transactions section description default style
  description: {
    content:
      'Bitexbot makes crypto investing effortless and automated, so now you would not miss the right time to buy.',
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['30px', '30px', '35px', '35px', '45px'],
    textAlign: ['left', 'left'],
    maxWidth: ['100%', '100%', '100%', '100%', '430px'],
  },
  sectionSubTitle: {
    content: 'Effortless trading for everyone.',
    as: 'span',
    // textAlign: "left",
    fontSize: ['16px', '16px', '18px', '20px', '20px'],
    fontFamily: 'Poppins',
    fontWeight: '600',
    lineHeight: '27px',
    color: '#525f7f',
    textAlign: ['left', 'left'],
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
  readMoreTitle: {
    content: 'Sale currently on hold. ',
    as: 'span',
    fontSize: ['18px', '18px', '20px', '20px', '20px'],
    lineHeight: ['25px', '27px', '27px', '27px', '27px'],
    fontWeight: '500',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '10px',
    textAlign: ['left', 'left'],
  },
};

export default ExchangesSection;
