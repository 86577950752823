import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Container from '../../common/components/UI/Container';
import FooterWrapper, { List, ListItem, BgImageWrapper } from './footer.style';
import { menuWidget } from '../../common/data/Crypto';
import { Row, Col } from 'reactstrap';
import { StaticImage } from 'gatsby-plugin-image';

const Footer = ({ row, col, colOne, colTwo, titleStyle }) => {
  const FooterBG = '../../common/assets/image/crypto/footer-bg.svg';
  const LogoImage =
    '../../common/assets/image/logo/bitexbot-logo-text-white-sm.png';
  return (
    <FooterWrapper id="footerSection">
      {/* <ContactSections /> */}
      <BgImageWrapper>
        <StaticImage src={FooterBG} alt="Footer background" />
      </BgImageWrapper>
      <Container noGutter mobileGutter width="1200px">
        <Row className="mainRow">
          <Col sm="12" md="4">
            <a href="/#">
              <StaticImage
                src={LogoImage}
                alt="Bitexbot"
                className="main-logo mb-2"
              />
            </a>
            <h6 className="text-white text-justify">
              Automated trading from TradingView strategies and alerts. Bitexbot
              convert every signals from TradingView (or almost anything) into a
              trade on Binance, Binance Futures, Bybit and BitMEX 24/7.
            </h6>
            <h5 className="text-white text-justify mt-2">
              We'll help you trade and invest better from the get-go!
            </h5>
          </Col>
          {/* End of footer logo column */}
          <Col sm="12" md="1" lg="2" className="mt-3"></Col>
          <Col sm="12" md="7" lg="6">
            <Row>
              {menuWidget.map((widget) => (
                <Col key={widget.id}>
                  {widget.display === true ? (
                    <Fragment>
                      <Heading content={widget.title} {...titleStyle} />
                      <List>
                        {widget.menuItems.map((item) => (
                          <ListItem key={`list__item-${item.id}`}>
                            {widget.scroll ? (
                              <AnchorLink href={item.url} offset={item.offset}>
                                {item.text}
                              </AnchorLink>
                            ) : (
                              <a
                                href={item.url}
                                target={item.target}
                                className="ListItem"
                              >
                                {item.text}
                              </a>
                            )}
                          </ListItem>
                        ))}
                      </List>
                    </Fragment>
                  ) : null}
                </Col>
              ))}
            </Row>
          </Col>
          {/* End of footer List column */}
        </Row>
        <Box className="row copyRight" {...row}>
          <Text
            content={`© ${new Date().getFullYear()} Bitexbot`}
            className="copyRightText"
          />
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-4px',
    mr: '-4px',
  },
  // Footer col one style
  colOne: {
    width: ['100%', '30%', '33%', '33%'],
    mb: ['30px', 0],
    pl: ['0px', 0],
    pr: ['0px', '0px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '70%', '67%', '67%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', 1 / 3, 1 / 3, 1 / 3],
    pl: [0, '15px'],
    pr: [0, '15px'],
    mb: ['30px', '30px'],
  },
  // widget title default style
  titleStyle: {
    color: '#FFFFFF',
    fontSize: ['15px', '16px', '16px', '18px', '18px'],
    fontWeight: '600',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '30px'],
    fontFamily: 'Poppins',
  },
  // Default logo size
  // logoStyle: {
  //   width: "128px",
  //   mb: "15px",
  // },
  // widget text default style
  textStyle: {
    color: '#FFFFFF',
    fontSize: '16px',
    mb: '12px',
    fontWeight: '600',
    fontFamily: 'Lato',
  },
};

export default Footer;
