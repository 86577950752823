/**
 * Round number to N decimal places, by default 2 decimal places
 * @param {number} num number to round
 * @param {number} decimal number of decimal places, default 2
 * @returns {number} return rounded number with decimal places
 */
export const roundNumber = (num, decimal = 2) => {
  const nDecimal = Math.pow(10, decimal);
  return Math.round((num + Number.EPSILON) * nDecimal) / nDecimal;
};
