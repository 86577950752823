import React from 'react';
import Container from '../../common/components/UI/Container';
import Heading from '../../common/components/Heading';
import Text from '../../common/components/Text';
import { SectionHeader } from '../crypto.style';
import SectionWrapper, { ContentWrapper, FaqItem } from './faq.style';
import { FAQ } from '../../common/data/Crypto';
import { Row, Col } from 'reactstrap';

const Faq = () => {
  const { slogan, title, faqs } = FAQ;
  return (
    <SectionWrapper id="faq">
      <Container>
        <SectionHeader>
          <Heading content={title} />
          <Text content={slogan} />
        </SectionHeader>
        <ContentWrapper>
          <Row className="pt-5">
            {faqs.map((faq) => (
              <Col key={faq.id} sm={12} md={6} className="mb-4 px-4">
                <FaqItem>
                  <Heading as="h4" content={faq.question} />
                  <Text content={faq.answer} />
                </FaqItem>
              </Col>
            ))}
          </Row>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Faq;
