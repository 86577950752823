import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';
import Image from '../../common/components/Image';
import FeatureBlock from '../../common/components/FeatureBlock';
import Container from '../../common/components/UI/Container';
import BannerBG from '../../common/assets/image/crypto/white_bg1.svg';
import BannerWrapper, { BgImageWrapper } from './bannerSection.style';

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  outlineBtnStyle,
}) => {
  const appUrl = process.env.GATSBY_BITEXBOT_APP_URL;
  const ButtonGroup = () => (
    <Fragment>
      <a href={`${appUrl}/signup`}>
        <Button title="Register" {...btnStyle} />
      </a>
      <a href={`${appUrl}/login`}>
        <Button
          title="Sign In"
          variant="textButton"
          icon={<i className="flaticon-next" />}
          {...outlineBtnStyle}
        />
      </a>
    </Fragment>
  );
  return (
    <BannerWrapper id="banner_section">
      <BgImageWrapper>
        <Image src={BannerBG} alt="banner background" />
      </BgImageWrapper>
      <Container>
        <Box className="row" {...row}>
          <Box className="col-md-8" {...col}>
            <FeatureBlock
              title={
                <Heading
                  as="h1"
                  content="Automated trading from TradingView strategies & alerts"
                  {...title}
                />
              }
              description={
                <Text
                  content="Place orders for Bitcoin, Ethereum, Cardano, and 250+ other cryptocurrencies on the world’s top crypto exchanges."
                  {...description}
                />
              }
              button={<ButtonGroup />}
            />
            <FeatureBlock
            // button={<ShareButtonGroup />}
            />
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '60%', '65%'],
  },
  title: {
    fontSize: ['26px', '34px', '42px', '42px', '47px'],
    fontWeight: '700',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.31',
  },
  description: {
    fontSize: ['14px', '16px', '18px', '18px', '20px'],
    color: '#fff',
    lineHeight: '30px',
    mb: '0',
    maxWidth: '550px',
  },
  btnStyle: {
    minWidth: ['120px', '156px'],
    fontSize: '15px',
    fontWeight: '700',
    borderRadius: '6px',
  },
  outlineBtnStyle: {
    minWidth: ['130px', '156px'],
    fontSize: '16px',
    fontWeight: '700',
    color: '#fff',
    p: '5px 10px',
  },
};

export default BannerSection;
