import AuthorOne from '../../assets/image/crypto/author-4.jpg';
import AuthorTwo from '../../assets/image/crypto/author-2.jpg';
import AuthorThree from '../../assets/image/crypto/author-3.jpg';
import AuthorFour from '../../assets/image/crypto/author-1.jpg';
import FEATURE1 from '../../assets/image/crypto/tf1.svg';
import FEATURE2 from '../../assets/image/crypto/tf2.svg';
import FEATURE3 from '../../assets/image/crypto/tf3.svg';
import FEATURE4 from '../../assets/image/crypto/tf4.svg';
import PROOF1 from '../../assets/image/crypto/proof1.svg';
import PROOF2 from '../../assets/image/crypto/proof2.svg';
import PROOF3 from '../../assets/image/crypto/proof3.svg';
import PROOF4 from '../../assets/image/crypto/proof4.svg';
import PROOF5 from '../../assets/image/crypto/proof5.svg';
import PROOF6 from '../../assets/image/crypto/proof6.svg';
import JACKPOTIMG from '../../assets/image/crypto/jackpot.svg';
import BETA1 from '../../assets/image/crypto/beta-1.svg';
import BETA2 from '../../assets/image/crypto/beta-2.svg';
import BETA3 from '../../assets/image/crypto/beta-3.svg';

export const MENU_ITEMS = [
  {
    label: 'Home',
    path: '#banner_section',
    offset: '0',
  },
  {
    label: 'Features',
    path: '#features_section',
    offset: '60',
  },
  {
    label: 'Exchanges',
    path: '#exchanges_section',
    offset: '120',
  },
  {
    label: 'Pricing',
    path: '#pricing_section',
    offset: '50',
  },
  // {
  //   label: "Payment Proofs",
  //   path: "#featureslider",
  //   offset: "-10",
  // },
  {
    label: 'FAQ',
    path: '#faq',
    offset: '50',
  },
  {
    label: 'Sign In',
    path: `${process.env.GATSBY_BITEXBOT_APP_URL}/login`,
    staticLink: true,
  },
];

export const TESTIMONIALS = [
  {
    review:
      'Best working experience  with this amazing team & in future, we want to work together',
    name: 'Jon Doe',
    designation: 'CEO of Dell Co.',
    avatar: `${AuthorOne}`,
  },
  {
    review:
      'Impressed with master class support of the team and really look forward for the future.',
    name: 'Jon Doe',
    designation: 'Co Founder of IBM',
    avatar: `${AuthorTwo}`,
  },
  {
    review:
      'I have bought more than 10 themes on ThemeForest, and this is the first one I review.',
    name: 'Jeny Doe',
    designation: 'Manager of Hp co.',
    avatar: `${AuthorThree}`,
  },
  {
    review:
      'Impressed with master class support of the team and really look forward for the future.',
    name: 'Jon Doe',
    designation: 'Manager of Hp co.',
    avatar: `${AuthorFour}`,
  },
];
export const TRANSACTIONS_FEATURE = [
  {
    image: FEATURE1,
    title: 'Fast Order Execution',
    des: 'We execute your orders in less than a second from an alert triggering on TradingView.',
  },
  {
    image: FEATURE3,
    title: 'Trading bots',
    des: 'Profit from every market move 24/7, with fully automated trading bots.',
  },
  {
    image: FEATURE2,
    title: 'Easy and Intuitive UI',
    des: 'Nothing to install and no browser extensions. Bitexbot trade even with your computer switched off.',
  },
  {
    image: FEATURE4,
    title: 'Order Notification',
    des: 'Get notified by email when an order is executed, which coin and how much of it was traded into.',
  },
];
export const PROOFS_FEATURE = [
  {
    image: PROOF1,
    title: 'Instant trading',
    des: 'Never miss a price swing.',
  },
  {
    image: PROOF2,
    title: 'No hidden fees',
    des: 'know our fees upfront.',
  },
  {
    image: PROOF3,
    title: 'Secure storage',
    des: 'Sleep with peace of mind.',
  },
  {
    image: PROOF4,
    title: 'Systematic trading',
    des: 'History intraday market.',
  },
  {
    image: PROOF5,
    title: 'Network Effect',
    des: 'Casinos contribute 1%.',
  },
  {
    image: PROOF6,
    title: 'Bigger Rewards',
    des: 'Players are incentivized.',
  },
];
export const SCALABLE_FEATURE = [
  {
    image: JACKPOTIMG,
    title: 'Daily Jackpot',
    des: '35000 CLV',
  },
  {
    image: JACKPOTIMG,
    title: 'Weekly Jackpot',
    des: '250000 CLV',
  },
  {
    image: JACKPOTIMG,
    title: 'Monthly Jackpot',
    des: '4999697 CLV',
  },
  {
    image: JACKPOTIMG,
    title: 'Yearly Jackpot',
    des: '300245785000 CLV',
  },
];

export const BETA_FEATURE = [
  {
    image: BETA1,
    title: 'SEPA Transfers',
    des: 'Deposit & Withdraw money.',
  },
  {
    image: BETA2,
    title: '24/7 Support',
    des: 'Always here for you.',
  },
  {
    image: BETA3,
    title: 'Secure',
    des: 'Your money is safe.',
  },
];

export const PRICING_TABLE_PRICE = {
  monthly: {
    months: 1,
    discount: 0,
    prices: ['6.95', '14.95', '22.95'],
    labels: [
      'Monthly subscription',
      'Monthly subscription',
      'Monthly subscription',
    ],
  },
  months_3: {
    months: 3,
    discount: 6,
    prices: ['6.53', '14.05', '21.57'],
    labels: [
      'Per month & 3 months subscription',
      'Per month & 3 months subscription',
      'Per month & 3 months subscription',
    ],
  },
  months_6: {
    months: 6,
    discount: 12,
    prices: ['6.12', '13.16', '20.20'],
    labels: [
      'Per month & 3 months subscription',
      'Per month & 6 months subscription',
      'Per month & 6 months subscription',
    ],
  },
  annual: {
    months: 12,
    discount: 16,
    prices: ['5.84', '12.56', '19.28'],
    labels: [
      'Per month & subscription yearly',
      'Per month & subscription yearly',
      'Per month & subscription yearly',
    ],
  },
};

export const PRICING_TABLE_CONTENT = [
  {
    name: 'Basic',
    type: 'BASIC',
    // freePlan: true,
    description: 'Occasional Trader',
    priceLabel: 'Only for first month',
    buttonLabel: 'START FREE 7-DAY TRIAL',
    url: `${process.env.GATSBY_BITEXBOT_APP_URL}/signup`,
    listItems: [
      {
        content: 'Up to <strong>50 TradingView Alerts</strong>',
      },
      {
        content: 'Up to <strong>5</strong> exchange <strong>API keys</strong>',
      },
      {
        content: '30 days trading history',
      },
      {
        content: 'Email notification',
      },
      {
        content: 'Testnets are always FREE.',
      },
    ],
  },
  {
    name: 'Pro',
    type: 'PRO',
    description: 'Professional Trader',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'START FREE 7-DAY TRIAL',
    url: `${process.env.GATSBY_BITEXBOT_APP_URL}/signup`,
    listItems: [
      {
        content: 'Up to <strong>150 TradingView Alerts</strong>',
      },
      {
        content: '<strong>5 Active Grid Trading Bots</strong>',
      },
      {
        content: 'Up to <strong>15</strong> exchange <strong>API keys</strong>',
      },
      {
        content: '90 days trading history',
      },
      {
        content: 'Email notification',
      },
      {
        content: 'Testnets are always FREE.',
      },
    ],
  },
  {
    name: 'Premium',
    type: 'PREMIUM',
    description: 'Maniac Trader',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'START FREE 7-DAY TRIAL',
    url: `${process.env.GATSBY_BITEXBOT_APP_URL}/signup`,
    listItems: [
      {
        content: '<strong>Unlimited TradingView Alerts<strong>',
      },
      {
        content: '<strong>20 Active Grid Trading Bots</strong>',
      },
      {
        content:
          '<strong>Unlimited</strong> exchange <strong>API keys</strong>',
      },
      {
        content: '365 days trading history',
      },
      {
        content: 'Email notification',
      },
      {
        content: 'Testnets are always FREE.',
      },
    ],
  },
];

export const menuWidget = [
  {
    id: 1,
    title: 'Support',
    display: true,
    menuItems: [
      {
        id: 1,
        url: 'https://docs.bitexbot.org',
        text: 'Documentation',
        target: '_blank',
      },
      {
        id: 2,
        url: 'https://discord.gg/G4vHmWEuXE',
        text: 'Discord Channel',
        target: '_blank',
      },
      // {
      //   id: 3,
      //   url: "#",
      //   text: "About Us",
      // },
      // {
      //   id: 4,
      //   url: "#",
      //   text: "Copyright",
      // },
      // {
      //   id: 5,
      //   url: "#",
      //   text: "Popular Campaign",
      // },
    ],
  },
  {
    id: 2,
    title: 'Our Information',
    display: true,
    menuItems: [
      {
        id: 1,
        url: '/terms-conditions',
        text: 'Terms & Conditions',
      },
      {
        id: 2,
        url: '/disclaimer',
        text: 'Disclaimer',
      },
      {
        id: 3,
        url: '/privacy-policy',
        text: 'Privacy Policy',
      },
    ],
  },
  {
    id: 3,
    title: 'Sitemap',
    scroll: true,
    display: true,
    menuItems: [
      {
        id: 1,
        url: '#banner_section',
        text: 'Home',
        offset: '0',
      },
      {
        id: 2,
        url: '#features_section',
        text: 'Features',
        offset: '60',
      },
      {
        id: 3,
        url: '#exchanges_section',
        text: 'Exchanges',
        offset: '120',
      },
      {
        id: 4,
        url: '#pricing_section',
        text: 'Pricing',
        offset: '50',
      },
      {
        id: 5,
        url: '#faq',
        text: 'FAQ',
        offset: '50',
      },
      // {
      //   id: 5,
      //   url: "#",
      //   text: "Sign In",
      // },
    ],
  },
];
export const Language_NAMES = [
  {
    label: 'English',
    value: 'eng',
  },
  {
    label: 'Chinese',
    value: 'chinese',
  },
  {
    label: 'Indian',
    value: 'indian',
  },
];

/* ------------------------------------ */
// Faq data section
/* ------------------------------------ */
export const FAQ = {
  title: 'Frequently Asked Questions',
  slogan: 'What question on your mind, lets find the answers',
  faqs: [
    {
      id: 1,
      question: 'How can I pay for Bitexbot PRO or PREMIUM?',
      answer:
        'CoinPayments handles the payment processing for us. CoinPayments is a crypto payment gateway that supports all major Coins. We guarantee you safe and secure online ordering.',
    },
    {
      id: 2,
      question: 'What is your Refund Policy?',
      answer:
        "There are no refunds for your orders, that's why we offer a Free 7-Day PREMIUM Trial so you can gain hands-on experience with Bitexbot before any subscription.",
    },
    {
      id: 3,
      question: 'Can I pay with crypto?',
      answer:
        'Sure, you can pay with crypto. Please read the FAQ "How can I pay for Bitexbot PRO or PREMIUM" for more info.',
    },
    {
      id: 4,
      question: 'Can I transfer my subscription to another Bitexbot account?',
      answer:
        'It is not possible to transfer a subscription from one account to another.',
    },
    {
      id: 5,
      question: 'I paid, but my subscription is not active!',
      answer:
        'Please get in touch with us so we may investigate further for you. You can contact us directly by email on support [at] bitexbot.org.',
    },
    // {
    //   id: 6,
    //   question: "What if I need help choosing the right domain?",
    //   answer:
    //     "Create a hub for cross-functional work that also works with all your other tools. Centralize and standardize communication with Miro. All premium functional are included here with updates.",
    // },
    // {
    //   id: 7,
    //   question: "Learn from community on Brandwagon",
    //   answer:
    //     "Brian Halligan knows that you need more than a great product to have a great brand. Hear his thoughts & score a peek at our wagon.",
    // },
  ],
};
